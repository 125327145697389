import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["next", "prev", "pageSelect"];
  static values = {
    currentPage: Number,
    totalPages: Number
  };

  connect() {
    console.log('Pagination connected')
    this.updateButtons();
  }

  nextPage() {
    if (this.currentPageValue < this.totalPagesValue) {
      this.currentPageValue++;
      this.updateButtons();
      this.goToPage();
    }
  }

  prevPage() {
    if (this.currentPageValue > 1) {
      this.currentPageValue--;
      this.updateButtons();
      this.goToPage();
    }
  }

  goToPage(event) {
    if (event) {
      this.currentPageValue = parseInt(event.target.value, 10);
    }
    this.updateButtons();
    this.dispatch("change", { detail: { page: this.currentPageValue } });
  }

  updateButtons() {
    this.prevTarget.disabled = this.currentPageValue === 1;
    this.nextTarget.disabled = this.currentPageValue === this.totalPagesValue;

    // Update the page select dropdown
    this.pageSelectTarget.value = this.currentPageValue;
  }
}
