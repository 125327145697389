import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['radio', 'min', 'max']

  connect() {
    this.selectInputMode = this.selectInputMode.bind(this);
    this.updateActiveSearch = this.updateActiveSearch.bind(this);
    this.firstCheckedValue = this.firstCheckedValue.bind(this);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.toggleInputAccordion = this.toggleInputAccordion.bind(this);
    this.checkForValues = this.checkForValues.bind(this);
    this.refresh = this.refresh.bind(this);
    this.key = this.element.dataset.key;
    this.mode = this.firstCheckedValue() || 'predefined';
    this.updateActiveSearch(true)
  }

  firstCheckedValue() {
    return this.radioTargets.filter(radio => radio.checked).map(radio => radio.value)[0];
  }

  toggleInputAccordion() {
    const that = this;
    this.radioTargets.forEach(radio => {
      const container = document.getElementById(`accordion-size-${radio.value}`);
      const disabled = radio.value != that.mode;

      if (container) {
        const elements = container.querySelectorAll('input[type="text"], input[type="number"], select, input[type="checkbox"], input[type="radio"]');
        Array.from(elements).forEach(element => { element.disabled = disabled });
      }
    })
  }

  radioTargetConnected(radio) {
    radio.addEventListener("change", this.selectInputMode.bind(this))
  }

  minTargetConnected(min) {
    min.addEventListener("change", this.refresh.bind(this))
  }

  maxTargetConnected(max) {
    max.addEventListener("change", this.refresh.bind(this))
  }

  refresh() {
    this.updateActiveSearch()
  }

  selectInputMode(event) {
    if (event.currentTarget.checked) {
      this.mode = event.currentTarget.value
      this.updateActiveSearch()
    }
  }

  toggleAccordion(event) {
    const checkbox = event.currentTarget.querySelector(`#${event.currentTarget.getAttribute('for')}`);
    if (checkbox) {
      checkbox.checked = true;
      this.mode = checkbox.value;
      this.toggleInputAccordion()
      this.updateActiveSearch()
    }
  }

  updateActiveSearch(init = false) {
    console.log('size_range', init,'updateActiveSearch')

    const accordion = document.getElementById(`accordion-flush-${this.key}`)
    const accordionBody = document.getElementById(`accordion-flush-body-${this.key}`)
    let values = []

    if (accordion) {
      const badge = accordion.querySelector(`.badge-${this.key}`)

      switch (this.mode) {
        case 'unknow':
          values = [this.mode]
          accordion.classList.add('filled-search')
          accordionBody.classList.add('filled-search')
          badge.innerHTML = values.length
          break;
        default:
          if (this.checkForValues(this.mode)) {
            values = [this.mode]
          }

          if (values.length > 0) {
            accordion.classList.add('filled-search')
            accordionBody.classList.add('filled-search')
            badge.innerHTML = values.length
          } else {
            accordion.classList.remove('filled-search')
            accordionBody.classList.remove('filled-search')
            badge.innerHTML = values.length
          }
          break;
      }

      if (init) {
        this.dispatch("init", { detail: { field_name: this.key, field_value: values } });
      } else {
        this.dispatch("change", { detail: { field_name: this.key, field_value: values } });
      }
    }
  }

  // This function checks if any input, select, checkbox, or radio has a value and enables the radio button accordingly
  checkForValues(mode) {
    const container = document.getElementById(`accordion-size-${mode || this.mode}`);

    if (container) {
      const elements = container.querySelectorAll('input[type="text"], input[type="number"], select, input[type="checkbox"], input[type="radio"]');
      // Determine if any element has a non-empty value or is checked
      const hasValue = Array.from(elements).some(element => {
        // For text inputs and selects, check if they have a non-empty value
        if (['text', 'number', 'email'].includes(element.type) || element.tagName.toLowerCase() === "select") {
          return element.value.trim() !== '';
        }
        // For checkboxes and radio buttons, check if they are checked
        if (element.type === "checkbox" || element.type === "radio") {
          return element.checked;
        }
        return false;
      });

      // Enable or disable the target radio button based on whether any value is present
      return hasValue;
    } else {
      return false;
    }
  }
}
