import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "mode"]

  initialize() {
    this.clicked = this.clicked.bind(this)
    this.refresh = this.refresh.bind(this)
    this.updateActiveSearch = this.updateActiveSearch.bind(this)
  }

  connect() {
    this.key = this.element.dataset.key
    this.updateActiveSearch('init')
  }

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener("change", this.clicked)
  }

  refresh() {
    // const checkboxesCount = this.checkboxTargets.length
    // const checkboxesCheckedCount = this.checked().length
    this.updateActiveSearch('refresh')
  }

  clicked() {
    this.updateActiveSearch('click')
  }

  checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked)
  }

  unchecked() {
    return this.checkboxTargets.filter((checkbox) => !checkbox.checked)
  }

  updateActiveSearch(action = 'change') {
    console.log('checkbox', action,'updateActiveSearch')

    const accordion = document.getElementById(`accordion-flush-${this.key}`)
    const accordionBody = document.getElementById(`accordion-flush-body-${this.key}`)
    const values = this.checked()

    if (accordion) {
      const badge = accordion.querySelector(`.badge-${this.key}`)
      if (values.length > 0) {
        accordion.classList.add('filled-search')
        accordionBody.classList.add('filled-search')
        badge.innerHTML = values.length
      } else {
        accordion.classList.remove('filled-search')
        accordionBody.classList.remove('filled-search')
        badge.innerHTML = values.length
      }
    }

    this.dispatch(action, { detail: { field_name: this.key, field_value: values } });
  }
}